// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
import "./fontawesome/brands";
import "./fontawesome/solid";
import "./fontawesome/regular";

function changeMenuMovil() {
    var boton = document.getElementById("btn-menu");

    if (boton) {
        var fondo_enlace = document.getElementById("fondo-enlace");
        var barra_lateral_izquierda = document.getElementById("barra-lateral-izquierda");

        boton.addEventListener('click', function(e) {
            if (fondo_enlace) {
                fondo_enlace.classList.toggle("active");
            }
            barra_lateral_izquierda.classList.toggle("active");
            e.preventDefault();
        });

        if (fondo_enlace) {
            fondo_enlace.addEventListener('click', function(e) {
                if (fondo_enlace) {
                    fondo_enlace.classList.toggle("active");
                }
                barra_lateral_izquierda.classList.toggle("active");
                e.preventDefault();
            });
        }
    }
}

function menuCookies() {
    var boton_cookies = document.getElementById("btn-cookies");

    if (boton_cookies) {
        boton_cookies.addEventListener('click', function(e) {
            document.cookie = 'allow_cookies=yes;expires=365;path=/;secure';
            document.getElementById("cookies-bar").remove();
        });
    }
}

function multiSelector() {
    var btn_left = document.getElementById("btnLeft");
    var btn_right = document.getElementById("btnRight");
    var r = document.getElementById("rightValues");
    var l = document.getElementById("leftValues");
    var btn_form = document.getElementById("btn-form");

    if (btn_left && btn_right && btn_form && r && l) {
        btn_left.addEventListener('click', function(e) {
            while (r.selectedOptions[0]) {
                l.add(r.selectedOptions[0]);
            }
        });

        btn_right.addEventListener('click', function(e) {
            while (l.selectedOptions[0]) {
                r.add(l.selectedOptions[0]);
            }
        });

        btn_form.addEventListener('click', function(e) {
            var options = r.getElementsByTagName("option");
            for (var i = 0; i < options.length; i++) {
                options[i].selected = "true";
            }
        });
    }
}


document.addEventListener("turbolinks:load", function() {
    require("javascripts/lazysizes")

    // MENU IZQUIERDA ACTIVAR
    changeMenuMovil();
    // FIN MENU IZQUIERDA

    // BANNER COOKIES
    menuCookies();
    // FIN BANNER COOKIES
    multiSelector();
});